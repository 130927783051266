
import { GetInvitesItem, GetInvitesResponse } from "@/types/responses"
import { DataTableHeader } from "vuetify"
const LogDialog = () => import("@/components/dialogs/LogDialog.vue")
import Vue from "vue"
export default Vue.extend({
	name: "Organization",
	components: {
		LogDialog,
	},
	mounted() {
		this.getInvites()
	},
	data(): {
		search: string
		logging: boolean
		selectedItem: null | GetInvitesItem
		loading: boolean
		invites: GetInvitesResponse["results"]
		headers: DataTableHeader[]
	} {
		return {
			search: "",
			logging: false,
			selectedItem: null,
			loading: false,
			invites: [],
			headers: [
				{ text: "Name", value: "displayName", sortable: false },
				{ text: "User Id", value: "matrixId", sortable: false },
				{ text: "Email", value: "emailAddress", sortable: false },
				{ text: "Type", value: "invitationType", sortable: false },
				{ text: "Status", value: "status", sortable: false },
				{ text: "Action", value: "action", sortable: false, filterable: false },
			],
		}
	},
	computed: {
		usersComplete(): GetInvitesResponse["results"] {
			return this.$vStore.state.provisioning.invites.filter((item: GetInvitesItem) => {
				return item.status === "Completed" || item.status === "InvitationSentAndJobCompleted" || item.status === "ProvisioningCompleted"
			})
		},
	},
	methods: {
		showLog(item: GetInvitesItem): void {
			this.selectedItem = item
			this.logging = true
		},
		async refreshInvites(): Promise<void> {
			this.loading = true
			try {
				await this.$vStore.dispatch("provisioning/updateInvites")
			} catch (e) {
				console.warn("Could not find any invites for this customer", e)
			}
			this.loading = false
		},
		async getInvites(): Promise<void> {
			if (this.loading) return
			if (this.$vStore.state.provisioning.invites.length > 0) return
			this.loading = true
			try {
				await this.$vStore.dispatch("provisioning/updateInvites")
			} catch (e) {
				console.warn("Could not find any invites for this customer", e)
			}
			this.loading = false
		},
	},
})
